import AccessTime from '@material-ui/icons/AccessTime';
import Cached from '@material-ui/icons/Cached';
import Cancel from '@material-ui/icons/Cancel';
import Check from '@material-ui/icons/Check';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import InsertDriveFile from '@material-ui/icons/InsertDriveFile';
import NotInterested from '@material-ui/icons/NotInterested';
import React from 'react';
import { Button, FunctionField, TextField, DateField } from 'react-admin';
import SimpleMultiTextField from '../../shared/multi-text-field/SimpleMultiTextField';
import StatusChip from '../../shared/status-chip';

export const statusInfos = {
  PAID: { color: 'colorGreen', icon: <Check /> },
  PENDING_EXTERNAL_PAYMENT: { color: 'colorBlue', icon: <AccessTime /> },
  PENDING: { color: 'colorBlue', icon: <AccessTime /> },
  REFUSED: { color: 'colorRed', icon: <NotInterested /> },
  ERROR: { color: 'colorRed', icon: <ErrorOutline /> },
  REFUNDED: { color: 'colorYellow', icon: <Cached /> },
  CANCELLED: { color: 'colorRed', icon: <Cancel /> },
};

export const InvoicesFields = ({
  translate,
  downloadPdf,
  openSetAsPaidDialog,
  openCancelDialog,
  openRefundDialog,
  downloadRefundNote,
  permissions,
  checkPermission,
  setInvoiceToPending,
}) => [
  <DateField key="invoiceDate" source="invoiceDate" sortable={false} />,
  <SimpleMultiTextField
    key="total"
    sources={['amount', 'currency']}
    sortBy="amount"
    sortable={false}
    label="resources.invoice.fields.total"
  />,
  <FunctionField
    key="pspReference"
    label="resources.invoice.fields.pspRef"
    render={ref => <span>{ref.pspReference ? ref.pspReference : '-'}</span>}
    sortable={false}
  />,
  <TextField key="type" source="type" label="resources.invoice.fields.type" sortable={false} />,
  <FunctionField
    key="status"
    label="resources.invoice.fields.status"
    render={ref => {
      if (ref && ref.invoicesStatus) {
        return ref.paidExternally || (ref.pspName && ref.pspName === 'ext_wire_transfer') ? (
          <StatusChip
            label={translate(`resources.invoice.status.PAID_EXTERNALLY`)}
            {...statusInfos[ref.invoicesStatus]}
          />
        ) : (
          <StatusChip
            label={translate(`resources.invoice.status.${ref.invoicesStatus}`)}
            {...statusInfos[ref.invoicesStatus]}
          />
        );
      }
      return null;
    }}
    sortable={false}
  />,
  <FunctionField
    key="downloadButton"
    label="resources.invoice.fields.actions"
    render={ref => (
      <>
        {checkPermission('VIEW_INVOICE_DOWNLOAD_OPTION') && (
          <Button
            id={`Selenium-Business-Invoices-list-downloadButton-${ref.id}-Button`}
            variant="outlined"
            label="resources.invoice.downloadButton"
            size="small"
            onClick={() => downloadPdf(ref.id)}
          >
            <InsertDriveFile />
          </Button>
        )}
        {ref &&
          ref.invoicesStatus &&
          (ref.invoicesStatus === 'REFUSED' ||
            ref.invoicesStatus === 'PENDING_EXTERNAL_PAYMENT' ||
            ref.invoicesStatus === 'PENDING') &&
          permissions === 'admin' && (
            <Button
              id={`Selenium-Business-Invoices-list-markAsPaid-${ref.id}-Button`}
              variant="outlined"
              label="resources.invoice.setAsPaid"
              size="small"
              style={{ marginLeft: 5 }}
              onClick={() => openSetAsPaidDialog(ref.id)}
            />
          )}
        {ref &&
          ref.invoicesStatus &&
          ref.invoicesStatus === 'PAID' &&
          (ref.pspName === 'ADYEN' || ref.pspName === 'STRIPE') &&
          ref.amount > 0 &&
          permissions === 'admin' && (
            <Button
              id={`Selenium-Business-Invoices-list-refund-${ref.id}-Button`}
              variant="outlined"
              label="resources.invoice.refund"
              size="small"
              style={{ marginLeft: 5 }}
              onClick={() => openRefundDialog(ref)}
            />
          )}
        {ref && ref.invoicesStatus && ref.invoicesStatus === 'REFUNDED' && permissions === 'admin' && (
          <Button
            id={`Selenium-Business-Invoices-list-refund-note-${ref.id}-Button`}
            variant="outlined"
            label="resources.invoice.refundNote"
            size="small"
            style={{ marginLeft: 5 }}
            onClick={() => downloadRefundNote(ref.id)}
          />
        )}
        {ref && (ref.invoicesStatus === 'ERROR' || ref.invoicesStatus === 'REFUSED') && permissions === 'admin' && (
          <Button
            variant="outlined"
            label="resources.invoice.retry"
            size="small"
            style={{ marginLeft: 5 }}
            onClick={() => setInvoiceToPending(ref.id)}
          />
        )}
        {ref &&
          (ref.invoicesStatus === 'ERROR' ||
            ref.invoicesStatus === 'REFUSED' ||
            ref.invoicesStatus === 'PENDING' ||
            ref.invoicesStatus === 'PENDING_AUTHENTICATION' ||
            ref.invoicesStatus === 'PENDING_EXTERNAL_PAYMENT') &&
          permissions === 'admin' && (
            <Button
              variant="outlined"
              label="resources.invoice.cancel"
              size="small"
              style={{ marginLeft: 5 }}
              onClick={() => openCancelDialog(ref)}
            />
          )}
      </>
    )}
    sortable={false}
  />,
  <TextField key="id" source="id" sortable={false} />,
  <TextField key="sequence" source="sequence" sortable={false} />,
  <TextField key="fleetId" source="fleetId" sortable={false} />,
  <TextField key="invoiceYear" source="invoiceYear" sortable={false} />,
  <TextField key="entityId" source="entityId" sortable={false} />,
  <TextField key="attempts" source="attempts" sortable={false} />,
  <TextField key="pspName" source="pspName" sortable={false} />,
  <TextField key="tripExpensesCumulativeId" source="tripExpensesCumulativeId" sortable={false} />,
];

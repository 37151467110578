import React from 'react';
import PropTypes from 'prop-types';
import {
  TextInput,
  SimpleShowLayout,
  AutocompleteInput,
  BooleanInput,
  FormDataConsumer,
  DisabledInput,
} from 'react-admin';
import Header from '../../../shared/header';
import { countryList } from '../../../shared/country';
import { required } from '../../../core/validators';

export const BusinessAddress = ({ resource, classes }) => (
  <SimpleShowLayout resource={resource}>
    <Header label="resources.business.businessAddress" />
    <TextInput
      source="address.streetName"
      validate={[required]}
      required
      className={classes.field}
      id="Selenium-Business-Create-Address-streetName-Input"
    />
    <TextInput
      source="address.postalCode"
      validate={[required]}
      required
      className={classes.field}
      id="Selenium-Business-Create-Address-postalCode-Input"
    />
    <TextInput
      source="address.city"
      validate={[required]}
      required
      className={classes.field}
      id="Selenium-Business-Create-Address-City-Input"
    />
    <AutocompleteInput
      source="address.country"
      choices={countryList}
      className={classes.field}
      autoComplete="nope"
      id="Selenium-Business-Create-Address-Country-Input"
    />
    <BooleanInput
      source="useAsBillingAddress"
      className={classes.fullWidth}
      id="Selenium-Business-Create-Address-useAsBillingAddress-Input"
    />
    <FormDataConsumer>
      {({ formData }) =>
        formData.useAsBillingAddress && (
          <React.Fragment>
            <DisabledInput
              resource={resource}
              source="address.streetName"
              required
              validate={[required]}
              className={classes.field}
              id="Selenium-Business-Create-Address-useAsBillingAddress-StreetName-Input"
            />
            <DisabledInput
              resource={resource}
              source="address.postalCode"
              required
              validate={[required]}
              className={classes.field}
            />
            <DisabledInput
              resource={resource}
              source="address.city"
              required
              validate={[required]}
              className={classes.field}
            />
            <DisabledInput resource={resource} source="address.country" className={classes.field} />
          </React.Fragment>
        )
      }
    </FormDataConsumer>
    <FormDataConsumer>
      {({ formData }) =>
        formData.useAsBillingAddress !== true && (
          <React.Fragment>
            <TextInput
              resource={resource}
              source="billingAddress.streetName"
              required
              validate={[required]}
              className={classes.field}
              id="Selenium-Business-Create-Address-notUseAsBillingAddress-StreetName-Input"
            />
            <TextInput
              resource={resource}
              source="billingAddress.postalCode"
              required
              validate={[required]}
              className={classes.field}
            />
            <TextInput
              resource={resource}
              source="billingAddress.city"
              required
              validate={[required]}
              className={classes.field}
            />
            <AutocompleteInput
              resource={resource}
              source="billingAddress.country"
              choices={countryList}
              className={classes.field}
              autoComplete="nope"
            />
          </React.Fragment>
        )
      }
    </FormDataConsumer>
  </SimpleShowLayout>
);

BusinessAddress.propTypes = {
  classes: PropTypes.shape({}),
  resource: PropTypes.string,
};

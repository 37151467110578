import React from 'react';
import PropTypes from 'prop-types';
import {
  TextInput,
  AutocompleteInput,
  SimpleShowLayout,
  FormDataConsumer,
  DisabledInput,
  BooleanInput,
} from 'react-admin';
import Header from '../../../shared/header';
import { countryList } from '../../../shared/country';
import { required } from '../../../core/validators';

export const BusinessAddress = ({ resource, classes }) => (
  <SimpleShowLayout resource={resource}>
    <Header label="common.null" />
    <Header label="resources.business.businessAddress" />
    <TextInput
      source="address.streetName"
      validate={[required]}
      required
      className={classes.fieldEdit}
      id="Selenium-Business-Edit-Address-StreetName-Input"
    />
    <TextInput
      source="address.postalCode"
      validate={[required]}
      required
      className={classes.fieldEdit}
      id="Selenium-Business-Edit-Address-postalCode-Input"
    />
    <TextInput
      source="address.city"
      validate={[required]}
      required
      className={classes.fieldEdit}
      id="Selenium-Business-Edit-Address-City-Input"
    />
    <AutocompleteInput
      source="address.country"
      choices={countryList}
      className={classes.fieldEdit}
      classes={{ suggestionsContainerOpen: classes.autoCompleteContainer }}
      autoComplete="nope"
      id="Selenium-Business-Edit-Address-Country-Input"
    />
    <BooleanInput
      source="useAsBillingAddress"
      className={classes.fullWidth}
      id="Selenium-Business-Edit-Address-useAsBillingAddress-Input"
    />
    <FormDataConsumer>
      {({ formData }) =>
        formData.useAsBillingAddress && (
          <React.Fragment>
            <DisabledInput
              resource={resource}
              source="address.streetName"
              validate={[required]}
              required
              className={classes.fieldEdit}
              id="Selenium-Business-Edit-Address-useAsBillingAddress-StreetName-Input"
            />
            <DisabledInput
              resource={resource}
              source="address.postalCode"
              validate={[required]}
              required
              className={classes.fieldEdit}
            />
            <DisabledInput
              resource={resource}
              source="address.city"
              validate={[required]}
              required
              className={classes.fieldEdit}
            />
            <DisabledInput resource={resource} source="address.country" className={classes.fieldEdit} />
          </React.Fragment>
        )
      }
    </FormDataConsumer>
    <FormDataConsumer>
      {({ formData }) =>
        !formData.useAsBillingAddress && (
          <React.Fragment>
            <TextInput
              resource={resource}
              source="billingAddress.streetName"
              validate={[required]}
              required
              className={classes.fieldEdit}
              id="Selenium-Business-Edit-Address-notUseAsBillingAddress-StreetName-Input"
            />
            <TextInput
              resource={resource}
              source="billingAddress.postalCode"
              validate={[required]}
              required
              className={classes.fieldEdit}
            />
            <TextInput
              resource={resource}
              source="billingAddress.city"
              validate={[required]}
              required
              className={classes.fieldEdit}
            />
            <AutocompleteInput
              resource={resource}
              source="billingAddress.country"
              choices={countryList}
              className={classes.fieldEdit}
              autoComplete="nope"
              classes={{ suggestionsContainerOpen: classes.autoCompleteContainer }}
            />
          </React.Fragment>
        )
      }
    </FormDataConsumer>
  </SimpleShowLayout>
);

BusinessAddress.propTypes = {
  classes: PropTypes.shape({}),
  resource: PropTypes.string,
};
